<template>
  <div class="personal-header">
    <HeaderBlock :isHideSearch="false" />
  </div>
  <section class="personal-section">
    <PersonalLeftBlock :hrefTo="'returns'" />
    <div class="block-right block-returns" v-if="isOpenBlockCreate">
      <a
        v-if="blockStep === '1'"
        @click="clickReturnClose"
        class="back back-open"
      >
        К списку возвратов
      </a>
      <a v-else @click="clickBlockStep" class="back back-open">
        На шаг назад
      </a>
      <div v-if="blockStep === '1'">
        <h2>Шаг 1. Укажите место заказа</h2>
        <div
          class="step-item"
          @click.stop="
            selectBlockStep('2', '1', 'Официальный сайт ultrabalance.ru')
          "
        >
          <span class="name">Заказ на официальном сайте ultrabalance.ru</span>
          <span class="description">официальный сайт</span>
        </div>
        <div
          class="step-item"
          @click.stop="selectBlockStep('1-1', '1', 'Заказ с другого сайта')"
        >
          <span class="name">Заказ с другого сайта</span>
          <span class="description">
            маркетплейсы, аптеки, оффлайн точки продаж
          </span>
        </div>
      </div>
      <div v-if="blockStep === '1-1'">
        <h2>Шаг 1.1. Укажите название площадки</h2>
        <div
          class="step-item"
          @click.stop="selectBlockStep('2', '1-1', 'ozon.ru')"
        >
          <span class="name">ozon.ru</span>
        </div>
        <div
          class="step-item"
          @click.stop="selectBlockStep('2', '1-1', 'wildberries.ru')"
        >
          <span class="name">wildberries.ru</span>
        </div>
        <div
          class="step-item"
          @click.stop="selectBlockStep('2', '1-1', 'market.yandex.ru')"
        >
          <span class="name">market.yandex.ru</span>
        </div>
        <div
          class="step-item"
          @click.stop="selectBlockStep('2', '1-1', 'detmir.ru')"
        >
          <span class="name">detmir.ru</span>
        </div>
        <div
          class="step-item"
          @click.stop="selectBlockStep('2', '1-1', 'megamarket.ru')"
        >
          <span class="name">megamarket.ru</span>
        </div>
        <div
          class="step-item"
          @click.stop="selectBlockStep('2', '1-1', 'goldapple.ru')"
        >
          <span class="name">goldapple.ru</span>
        </div>
        <div
          class="step-item"
          @click.stop="selectBlockStep('2', '1-1', 'apteka.ru')"
        >
          <span class="name">apteka.ru</span>
        </div>
        <div
          class="step-item"
          @click.stop="selectBlockStep('2', '1-1', 'eapteka.ru')"
        >
          <span class="name">eapteka.ru</span>
        </div>
        <div class="step-item" @click.stop="clickOpenStep11Input">
          <span class="name" v-if="!isOpenStep11Input">другая (указать)</span>
          <div v-else class="input-block">
            <input
              type="text"
              ref="input"
              placeholder="Введите значение"
              v-model="step11Input"
            />
            <button @click.stop="clickStep11Input" class="desktop">
              Сохранить и продолжить
            </button>
            <button @click.stop="clickStep11Input" class="mobile">
              Сохранить
            </button>
            <a @click.stop="isOpenStep11Input = false">Отмена</a>
          </div>
        </div>
      </div>
      <div v-if="blockStep === '2'">
        <h2>Шаг 2. Укажите причину возврата</h2>
        <div
          class="step-item"
          @click.stop="selectBlockStep('3', '2', 'Пересорт товара')"
        >
          <span class="name">Пересорт товара</span>
          <span class="description">пришло не то что заказывал</span>
        </div>
        <div
          class="step-item"
          @click.stop="
            selectBlockStep('3', '2', 'Не полная комплектация заказа')
          "
        >
          <span class="name">Не полная комплектация заказа</span>
          <span class="description">пришел не полный заказ</span>
        </div>
        <div
          class="step-item"
          @click.stop="selectBlockStep('3', '2', 'Качество продукта')"
        >
          <span class="name">Качество продукта</span>
          <span class="description">
            внешние или внутренние дефекты, фасовка, сроки годности
          </span>
        </div>
        <div
          class="step-item"
          @click.stop="
            selectBlockStep('3', '2', 'Негативная реакция организма на продукт')
          "
        >
          <span class="name">Негативная реакция организма на продукт</span>
          <span class="description">
            при приеме продукта обнаружились противопоказания или отмена приема
            врачем
          </span>
        </div>
        <div
          class="step-item"
          @click.stop="selectBlockStep('3', '2', 'Заказ не доставлен')"
        >
          <span class="name">Заказ не доставлен</span>
          <span class="description">
            не удалось забрать заказ у курьера или в ПВЗ
          </span>
        </div>
      </div>
      <div v-if="blockStep === '3-1'">
        <h2>Шаг 3. Выберите заказ</h2>
        <div class="step-item" v-for="item in orders" :key="item">
          <div
            class="order-block"
            @click.stop="selectBlockStep('4', '3-1', item.id)"
          >
            <div class="info">
              <span>
                <span>Заказ {{ item.id }}</span>
                от {{ dateFormatString(item.created_at) }}
              </span>
              <span>{{ formatPrice(item.total) }} руб.</span>
            </div>
            <div class="products">
              <a
                v-for="product in item.products"
                :key="product"
                class="product-item"
                :style="{
                  background: product.product.images[0].image.background_color,
                }"
              >
                <img
                  :src="product.product.images[0].image.image_object_small"
                />
                <span v-if="product.quantity > 1">{{ product.quantity }}</span>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div v-if="blockStep === '3-2'" class="step-block">
        <h2>Шаг 3. Загрузите данные по заказу:</h2>
        <div class="loading-files">
          <h4>Загрузите чек*:</h4>
          <label>
            <input type="file" ref="file1" @change="handleFileUploadStep321" />
            <span></span>
          </label>
          <div v-if="step32Files1.length > 0" class="files">
            <div v-for="item in step32Files1" :key="item">
              <img
                v-if="item.isLoading"
                src="@/assets/images/shop-loading.svg"
              />
              <span v-else>
                {{ item.title }}
                <img
                  src="@/assets/images/shop-close-red.svg"
                  @click="deleteStep32Files1(item)"
                />
              </span>
            </div>
          </div>
        </div>
        <div class="loading-files">
          <h4>
            Загрузите скрин с личного кабинета, на котором видна дата заказа,
            сумма заказа и товары:
          </h4>
          <label>
            <input type="file" ref="file2" @change="handleFileUploadStep322" />
            <span></span>
          </label>
          <div v-if="step32Files2.length > 0" class="files">
            <div v-for="item in step32Files2" :key="item">
              <img
                v-if="item.isLoading"
                src="@/assets/images/shop-loading.svg"
              />
              <span v-else>
                {{ item.title }}
                <img
                  src="@/assets/images/shop-close-red.svg"
                  @click="deleteStep32Files2(item)"
                />
              </span>
            </div>
          </div>
        </div>
        <button v-if="isLoading" disabled="disabled">Идет загрузка...</button>
        <button v-else @click.stop="selectBlockStep32">
          Продолжить оформление
        </button>
      </div>
      <div v-if="blockStep === '4'" class="step-block">
        <h2>Шаг 4. Опишите проблему подробнее</h2>
        <div class="group-input" :class="{ error: isErrorStep4Input }">
          <small>Добавьте комментарий:</small>
          <textarea v-model="step4Input"></textarea>
        </div>
        <div class="loading-files">
          <h4>Загрузите фото продукта:</h4>
          <label>
            <input type="file" ref="file4" @change="handleFileUploadStep4" />
            <span></span>
          </label>
          <div v-if="step4Files.length > 0" class="files">
            <div v-for="item in step4Files" :key="item">
              <img
                v-if="item.isLoading"
                src="@/assets/images/shop-loading.svg"
              />
              <span v-else>
                {{ item.title }}
                <img
                  src="@/assets/images/shop-close-red.svg"
                  @click="deleteStep4Files(item)"
                />
              </span>
            </div>
          </div>
        </div>
        <button v-if="isLoading" disabled="disabled">Идет загрузка...</button>
        <button v-else @click.stop="selectBlockStep4">
          Продолжить оформление
        </button>
      </div>
      <div v-if="blockStep === '5'">
        <h2>Шаг 5. Какую операцию вы хотите произвести</h2>
        <div
          class="step-item"
          @click.stop="
            selectBlockStep(
              '5-1-1',
              '5',
              'Возврат денежных средств за оплаченный заказ'
            )
          "
        >
          <span class="name">Возврат денежных средств за оплаченный заказ</span>
          <span class="description">пришло не то что заказывал</span>
        </div>
        <div
          class="step-item"
          @click.stop="
            selectBlockStep('5-2', '5', 'Замена или доотправка товара')
          "
        >
          <span class="name">Замена или доотправка товара</span>
          <span class="description">пришел не полный заказ</span>
        </div>
      </div>
      <div v-if="blockStep === '5-1-1'">
        <h2>Шаг 5.1. Оформление возврата денежных средств</h2>
        <div
          class="step-item"
          @click.stop="
            selectBlockStep(
              '5-1-2',
              '5-1-1',
              'Возврат денежных средств на карту'
            )
          "
        >
          <span class="name">Возврат денежных средств на карту</span>
          <span class="description">
            необходимо заполнить реквизиты номера счета и БИК банка, указать ИНН
            получателя и ФИО владельца счета
          </span>
        </div>
        <div
          class="step-item"
          @click.stop="
            selectBlockStep(
              'stop',
              '5',
              'Возврат стоимости заказа на бонусный счет'
            )
          "
        >
          <span class="name">Возврат стоимости заказа на бонусный счет</span>
          <span class="description">
            начисление бонусов в кабинет сайта ultrabalance.ru
          </span>
        </div>
      </div>
      <div v-if="blockStep === '5-1-2'" class="step-form">
        <h2>Шаг 5.2. Реквизиты для возврата денежных средств</h2>
        <div class="group-input" :class="{ error: isErrorStep512Name }">
          <small>ФИО владельца счета</small>
          <input
            type="text"
            placeholder="ФИО владельца счета"
            v-model="step512Name"
          />
        </div>
        <div class="group-input" :class="{ error: isErrorStep512Inn }">
          <small>ИНН</small>
          <input
            type="text"
            placeholder="ИНН"
            ref="number"
            v-model="step512Inn"
          />
        </div>
        <div class="group-input" :class="{ error: isErrorStep512Number }">
          <small>Расчетный счет</small>
          <input
            type="text"
            placeholder="Расчетный счет"
            ref="number"
            v-model="step512Number"
          />
        </div>
        <div class="group-input" :class="{ error: isErrorStep512Bank }">
          <small>Банк</small>
          <vue-dadata
            v-model="step512Bank"
            :token="dadataToken"
            :url="dadataUrlBank"
            :debounceWait="'100ms'"
            v-model:suggestion="suggestionBank"
            placeholder="Банк"
          />
        </div>
        <div class="group-input" :class="{ error: isErrorStep512Amount }">
          <small>Сумма к возврату, руб.</small>
          <input
            type="number"
            placeholder="Сумма к возврату, руб."
            v-model="step512Amount"
          />
        </div>
        <button @click.stop="selectBlockStep512">
          Подписать заявление на возврат
        </button>
        <span class="agree">
          Заполняя форму вы соглашаетесь с
          <a href="/policy" target="_blank">политикой конфиденциальности</a> и
          <a href="/terms" target="_blank">условиями использования сайта</a>
        </span>
      </div>
      <div v-if="blockStep === '5-2'" class="step-form">
        <h2>Шаг 5.2. Данные для доотправки товара или замены товара</h2>
        <div class="checkout-items">
          <div
            class="checkout-item"
            @click.stop="isOpenProfileName = true"
            v-if="!profile.firstName"
          >
            <span>Мои данные</span>
            <span><a>Укажите данные получателя</a></span>
          </div>
          <div
            v-else
            class="checkout-item"
            @click.stop="isOpenProfileName = true"
          >
            <span>{{ profile.firstName }} {{ profile.lastName }}</span>
            <span v-if="profile.email">
              {{ profile.phone }};{{ profile.email }}
            </span>
            <span v-else>{{ profile.phone }}</span>
          </div>
          <div
            v-if="selectDelivery"
            class="checkout-item"
            @click.stop="isDeliveries = true"
          >
            <span v-if="selectDelivery.type === 2">Доставка курьером</span>
            <span v-else-if="selectDelivery.info_point.is_postamat">
              Почтомат
              {{ selectDelivery.info_point.delivery_service_name }}
            </span>
            <span v-else>
              Пункт самовывоза
              {{ selectDelivery.info_point.delivery_service_name }}
            </span>
            <span v-if="selectDelivery.type === 1">
              {{ selectDelivery.info_point.address }}
            </span>
            <span v-else>{{ selectDelivery.address_raw }}</span>
          </div>
          <div v-else class="checkout-item" @click.stop="isAddDelivery = true">
            <span>Способ доставки</span>
            <span>Выберите способ и адрес доставки</span>
          </div>
        </div>
        <button @click.stop="selectBlockStep52">
          Подписать заявление на возврат
        </button>
        <span class="agree">
          Заполняя форму вы соглашаетесь с
          <a href="/policy" target="_blank">политикой конфиденциальности</a> и
          <a href="/terms" target="_blank">условиями использования сайта</a>
        </span>
      </div>
    </div>
    <div class="block-right block-returns" v-else>
      <a @click="$router.push({ name: 'personal' })" class="back">В профиль</a>
      <h1>
        Мои возвраты и замены
        <span>{{ returns.length }}</span>
      </h1>
      <div class="returns-add">
        <div>
          <span>
            Создание заявки на возврат с поддерживаемых точек продаж компании
          </span>
          <a href="/return-policy" target="_blank">Подробнее</a>
        </div>
        <button @click.stop="clickNewReturn">Создать возврат</button>
      </div>
      <div class="destination-tabs">
        <span
          :class="{ active: destinationValue === 0 }"
          @click.stop="destinationValue = 0"
        >
          Все
        </span>
        <span
          :class="{ active: destinationValue === 1 }"
          @click.stop="destinationValue = 1"
        >
          В обработке
        </span>
        <span
          :class="{ active: destinationValue === 2 }"
          @click.stop="destinationValue = 2"
        >
          Возврат выполнен
        </span>
        <span
          :class="{ active: destinationValue === 3 }"
          @click.stop="destinationValue = 3"
        >
          Отменённые
        </span>
      </div>
      <div
        v-for="item in returnsDisplay"
        :key="item"
        class="return-item"
        @click.stop="openReturn(item)"
      >
        <div>
          <span>Возврат {{ item.id }} с {{ item.source }}</span>
          <span>от {{ dateFormatString(item.created_at) }}</span>
        </div>
        <span v-if="item.status.name === 'Черновик'" class="red">
          заполните заявку
        </span>
        <span v-else :class="{ disabled: item.status.destination === 3 }">
          {{ item.status.name }}
        </span>
      </div>
    </div>
  </section>
  <div class="personal-footer">
    <FooterBlock />
  </div>
  <ProfileNameBlock
    v-if="isOpenProfileName"
    :profile="profile"
    :isCheckout="false"
    :isPartner="true"
    :isLastName="true"
    :isDisplayEmail="true"
  />
  <div v-if="isDeliveries" class="deliveries-popup shop-popup">
    <div class="content">
      <img
        @click="isDeliveries = false"
        src="@/assets/images/shop-close.svg"
        class="close"
      />
      <h5>Адрес доставки</h5>
      <div
        v-for="item in deliveries"
        :key="item"
        class="item"
        :class="{ active: selectDeliveryId == item.id }"
        @click.stop="
          (selectDelivery = item),
            (selectDeliveryId = item.id),
            (isDeliveries = false)
        "
      >
        <span v-if="item.type === 2">Доставка курьером</span>
        <span v-else-if="item.info_point.is_postamat">
          Почтомат {{ item.info_point.delivery_service_name }}
        </span>
        <span v-else>
          Пункт самовывоза {{ item.info_point.delivery_service_name }}
        </span>
        <span v-if="item.type === 1">{{ item.info_point.address }}</span>
        <span v-else>{{ item.address_raw }}</span>
      </div>
      <div
        class="select-none"
        @click.stop="(isDeliveries = false), (isAddDelivery = true)"
      >
        Добавить новый адрес
      </div>
    </div>
  </div>
  <AddDeliveryPopupBlock
    v-if="isAddDelivery"
    :deliveriesPoints="deliveriesPoints"
    :isCreate="true"
    :isSettings="false"
    :isCheckout="true"
  />
  <ErrorTooltipBlock
    v-if="errorTooltip === 'delivery'"
    :header="'Ошибка'"
    :text="'Укажите доставку'"
  />
  <div
    v-if="isStepStop || openReturnId"
    class="return-request-popup shop-popup"
  >
    <div class="content">
      <img
        @click="closePopupReturn"
        src="@/assets/images/shop-close.svg"
        class="close"
      />
      <h5 v-if="isStepStop">Подпись заявления на возврат или замену</h5>
      <h5 v-else>Возврат {{ openReturnId }}</h5>
      <span class="agree" v-if="isStepStop">
        Подписывая данное заявление вы подтверждаете достоверность указанных
        данных, согласие с
        <a href="/return-policy" target="_blank">правилами возврата</a>
        и согасие на примененение
        <a href="/terms" target="_blank">простой электронной подписи</a>
      </span>
      <div class="checkout-items">
        <div class="checkout-item none">
          <span>Мои данные</span>
          <span>{{ profile.firstName }} {{ profile.lastName }}</span>
        </div>
        <div class="checkout-item none">
          <span>Место покупки</span>
          <span v-if="stepValue['1'] === 'Заказ с другого сайта'">
            Заказ с {{ stepValue["1-1"] }}
          </span>
          <span v-else>Официальный сайт ultrabalance.ru</span>
        </div>
        <div class="checkout-item none" v-if="stepValue['3-1']">
          <span>Заказ</span>
          <span>Заказ {{ stepValue["3-1"] }}</span>
        </div>
        <div class="checkout-item none" v-else>
          <span>Данные по заказу</span>
          <span v-if="stepValue['3-2']['files2'].length > 0">
            Чек:
            <a
              :href="getValueFile(stepValue['3-2']['files1'][0]['file_name'])"
              target="_blank"
            >
              {{ stepValue["3-2"]["files1"][0]["title"] }}
            </a>
            <br />
            Скрин заказа:
            <a
              :href="getValueFile(stepValue['3-2']['files2'][0]['file_name'])"
              target="_blank"
            >
              {{ stepValue["3-2"]["files2"][0]["title"] }}
            </a>
          </span>
          <span v-else>
            Чек:
            <a
              :href="getValueFile(stepValue['3-2']['files1'][0]['file_name'])"
              target="_blank"
            >
              {{ stepValue["3-2"]["files1"][0]["title"] }}
            </a>
            <br />
            Скрин заказа: не загружен
          </span>
        </div>
        <div class="checkout-item none">
          <span>Причина создания заявления</span>
          <span>{{ stepValue["2"] }}</span>
        </div>
        <div class="checkout-item none">
          <span>Подробное описание проблемы</span>
          <span>
            {{ stepValue["4"]["value"] }} <br />
            <span v-for="file in stepValue['4']['files']" :key="file">
              <a :href="getValueFile(file['file_name'])" target="_blank">
                {{ file["title"] }}
              </a>
              <br />
            </span>
          </span>
        </div>
        <div
          class="checkout-item none"
          v-if="stepValue['5'] === 'Замена или доотправка товара'"
        >
          <span>Требуемая операция</span>
          <span>Замена или доотправка товара</span>
        </div>
        <div
          class="checkout-item none"
          v-else-if="stepValue['5-1-1'] === 'Возврат денежных средств на карту'"
        >
          <span>Требуемая операция</span>
          <span>Возврат денежных средств на карту</span>
        </div>
        <div class="checkout-item none" v-else>
          <span>Требуемая операция</span>
          <span>Возврат стоимости заказа на бонусный счет</span>
        </div>
        <div
          class="checkout-item none"
          v-if="stepValue['5'] === 'Замена или доотправка товара'"
        >
          <span>Адрес для отправки</span>
          <span v-if="selectDelivery && selectDelivery.type === 2">
            Курьер: {{ stepValue["5-2"] }}
          </span>
          <span v-else-if="selectDelivery && selectDelivery.info_point">
            ПВЗ {{ selectDelivery.info_point.delivery_service_name }}:
            {{ stepValue["5-2"] }}
          </span>
          <span v-else>{{ stepValue["5-2"] }}</span>
        </div>
        <div
          class="checkout-item none"
          v-else-if="stepValue['5-1-1'] === 'Возврат денежных средств на карту'"
        >
          <span>Банковские реквизиты для возврата</span>
          <span>
            ФИО: {{ step512Name }} <br />
            ИНН получателя: {{ step512Inn }} <br />
            Р/С: {{ step512Number }} <br />
            БИК банка: {{ step512Bank }} <br />
            Сумма к возврату: {{ step512Amount }} руб.
          </span>
        </div>
      </div>
      <span class="agree" v-if="isStepStop">
        Подписывая данное заявление вы подтверждаете достоверность указанных
        данных, согласие с
        <a href="/return-policy" target="_blank">правилами возврата</a>
        и согасие на примененение
        <a href="/terms" target="_blank">простой электронной подписи</a>
      </span>
      <button v-if="!isCheckPhone && isStepStop" @click="setCheckPhone">
        Подтвердить СМС-кодом
      </button>
      <div v-else-if="isStepStop">
        <span class="agree">
          На номер {{ profile.phone }} поступит 4-х значный СМС код. Укажите код
          в поле ниже для подтверждения операции.
        </span>
        <p v-if="isErrorCode" class="error-code">
          Вы ввели не верный код . Исправьте его, или отправьте новый
        </p>
        <div class="group-input code" :class="{ error: isErrorCode }">
          <small>4-х значный код-пароль</small>
          <input
            type="tel"
            ref="code"
            v-model="code"
            placeholder="••••"
            maxlength="4"
          />
        </div>
        <span class="send" :class="{ disabled: timer > 0 }" @click="sendRepeat">
          Отправить код повторно
          <span v-if="timer > 0"> ({{ timerText }})</span>
        </span>
        <button @click="confirmPhone">Подтвердить</button>
      </div>
    </div>
  </div>
</template>

<script>
import mainMixin from "@/mixins/mainMixin";
import pageMixin from "@/mixins/pageMixin";
import profileMixin from "@/mixins/profileMixin";
import productMixin from "@/mixins/productMixin";
import phoneMixin from "@/mixins/phoneMixin";
import HeaderBlock from "@/components/HeaderBlock.vue";
import FooterBlock from "@/components/FooterBlock.vue";
import PersonalLeftBlock from "@/components/PersonalLeftBlock.vue";
import ProfileNameBlock from "@/components/ProfileNameBlock.vue";
import AddDeliveryPopupBlock from "@/components/AddDeliveryPopupBlock.vue";
import ErrorTooltipBlock from "@/components/ErrorTooltipBlock.vue";
import { VueDadata } from "vue-dadata";
import "vue-dadata/dist/style.css";
import "@/assets/css/personal.css";
import api from "@/api";
import Const from "@/api/const";

export default {
  mixins: [mainMixin, pageMixin, profileMixin, productMixin, phoneMixin],
  components: {
    HeaderBlock,
    FooterBlock,
    PersonalLeftBlock,
    ProfileNameBlock,
    AddDeliveryPopupBlock,
    ErrorTooltipBlock,
    VueDadata,
  },
  data() {
    return {
      isOpenBlockCreate: false,
      destinationValue: 0,
      returns: [],
      returnsDisplay: [],
      blockStep: "1",
      stepValue: {},
      isOpenStep11Input: false,
      step11Input: "",
      step4Input: "",
      isErrorStep4Input: false,
      orders: [],
      step32Files1: [],
      step32Files2: [],
      step4Files: [],
      isLoading: false,
      isErrorStep512Name: false,
      isErrorStep512Inn: false,
      isErrorStep512Number: false,
      isErrorStep512Bank: false,
      isErrorStep512Amount: false,
      step512Name: "",
      step512Inn: "",
      step512Number: "",
      step512Bank: "",
      step512Amount: "",
      dadataToken: "3027d788a1f31d494c363b6dc6b301dd3b9c4f13",
      dadataUrlBank:
        "https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/bank",
      suggestionBank: {},
      isOpenProfileName: false,
      deliveries: [],
      selectDelivery: null,
      selectDeliveryId: null,
      isDeliveries: false,
      isAddDelivery: false,
      deliveriesPoints: "",
      courierServices: [],
      errorTooltip: "",
      isStepStop: false,
      openReturnId: null,
      isCheckPhone: false,
      isErrorCode: false,
      code: "",
      editReturnId: null,
      apiUrl: Const.API_URL,
    };
  },
  async created() {
    document.title = "Мои возвраты и замены";
    if (!this.authToken) window.location.href = "/";

    try {
      const result = await api.profile.getUserOrders({});
      this.orders = result["data"]["results"];
    } catch (error) {
      console.log(error);
    }
    try {
      const result = await api.profile.getUserDelivery();
      let deliveryLast = { updatedAt: 0 };
      for (let item in result["data"]["results"]) {
        item = result["data"]["results"][item];
        let cost = 0;
        if (item["type"] === 1) cost = item["info_point"]["cost_of_delivery"];
        let delivery = {
          id: item["id"],
          type: item["type"],
          city: item["city"],
          address_raw: item["address_raw"],
          pointId: item["point"],
          info_point: item["info_point"],
          cost: cost,
          updatedAt: new Date(item["updated_at"]).getTime(),
          isDefault: item["is_default"],
        };
        this.deliveries.push(delivery);
        if (delivery["isDefault"]) {
          this.selectDeliveryId = delivery["id"];
          this.selectDelivery = delivery;
        }
        if (delivery["updatedAt"] > deliveryLast["updatedAt"]) {
          deliveryLast = delivery;
        }
      }
      if (!this.selectDeliveryId && deliveryLast["id"]) {
        this.selectDeliveryId = deliveryLast["id"];
        this.selectDelivery = deliveryLast;
      }
    } catch (error) {
      console.log(error);
    }
  },
  async mounted() {
    let that = this;
    document.addEventListener("keydown", function (evt) {
      if (evt.which === 27) {
        that.isDeliveries = false;
        that.isAddDelivery = false;
        that.isStepStop = false;
        that.openReturnId = null;
        that.isOpenPopup(false);
      }
    });
    const urlParams = new URLSearchParams(window.location.search);
    let return_id = urlParams.get("return_id");
    try {
      const result = await api.profile.getUserReturns();
      this.returns = result["data"]["results"];
      if (return_id) {
        for (let item in this.returns) {
          if (Number(return_id) === this.returns[item]["id"]) {
            this.openReturn(this.returns[item]);
          }
        }
      }
      this.reloadReturnsDisplay();
    } catch (error) {
      console.log(error);
    }
  },
  watch: {
    destinationValue() {
      this.reloadReturnsDisplay();
    },
    suggestionBank() {
      this.step512Bank = "БИК: " + this.suggestionBank["data"]["bic"] + ", ";
      this.step512Bank += this.suggestionBank["data"]["name"]["short"];
    },
    isStepStop(isOpen) {
      this.isOpenPopup(isOpen);
    },
    openReturnId(isOpen) {
      if (isOpen) this.isOpenPopup(true);
      else this.isOpenPopup(false);
    },
  },
  methods: {
    reloadReturnsDisplay() {
      this.returnsDisplay = [];
      if (this.destinationValue === 0) {
        this.returnsDisplay = this.returns;
        return false;
      }
      for (let item in this.returns) {
        item = this.returns[item];
        if (this.destinationValue === item["status"]["destination"]) {
          this.returnsDisplay.push(item);
        }
      }
    },
    clickBlockStep() {
      this.stepValue[this.blockStep] = null;
      if (this.blockStep === "1-1") this.blockStep = "1";
      if (this.blockStep === "2" && !this.stepValue["1-1"]) {
        this.blockStep = "1";
      }
      if (this.blockStep === "2" && this.stepValue["1-1"]) {
        this.blockStep = "1-1";
      }
      if (this.blockStep === "3-1") this.blockStep = "2";
      if (this.blockStep === "3-2") this.blockStep = "2";
      if (this.blockStep === "4" && !this.stepValue["3-1"]) {
        this.blockStep = "3-2";
      }
      if (this.blockStep === "4" && this.stepValue["3-1"]) {
        this.blockStep = "3-1";
      }
      if (this.blockStep === "5") this.blockStep = "4";
      if (this.blockStep === "5-1-1") this.blockStep = "5";
      if (this.blockStep === "5-1-2") this.blockStep = "5-1-1";
      if (this.blockStep === "5-2") this.blockStep = "5";
    },
    selectBlockStep(step, key, value) {
      this.stepValue[key] = value;
      if (this.editReturnId) this.updateReturn();
      if (step === "stop") {
        this.isStepStop = true;
        return false;
      }
      this.blockStep = step;
      if (step === "3" && !this.stepValue["1-1"]) this.blockStep = "3-1";
      if (step === "3" && this.stepValue["1-1"]) this.blockStep = "3-2";
      if (step === "2" && !this.editReturnId) this.createReturn();
      if (
        key === "4" &&
        this.stepValue["2"] === "Негативная реакция организма на продукт"
      ) {
        this.selectBlockStep(
          "5-1-1",
          "5",
          "Возврат денежных средств за оплаченный заказ"
        );
      }
    },
    clickOpenStep11Input() {
      this.isOpenStep11Input = true;
      let that = this;
      setTimeout(function () {
        that.$refs["input"].focus();
      }, 100);
    },
    clickStep11Input() {
      if (this.step11Input !== "") {
        this.isOpenStep11Input = false;
        this.selectBlockStep("2", "1-1", this.step11Input);
      }
    },
    async handleFileUploadStep321() {
      this.isLoading = true;
      this.step32Files1.push({ isLoading: true });
      try {
        let payload = new FormData();
        payload.append("file", this.$refs.file1.files[0]);
        payload.append("folder", "returns");
        const result = await api.auth.uploadFile(payload);
        let files = this.step32Files1;
        this.step32Files1 = [];
        for (let item in files) {
          if (!files[item].isLoading) this.step32Files1.push(files[item]);
        }
        this.step32Files1.push(result["data"]);
        this.isLoading = false;
      } catch (error) {
        console.log(error);
      }
    },
    async handleFileUploadStep322() {
      this.isLoading = true;
      this.step32Files2.push({ isLoading: true });
      try {
        let payload = new FormData();
        payload.append("file", this.$refs.file2.files[0]);
        payload.append("folder", "returns");
        const result = await api.auth.uploadFile(payload);
        let files = this.step32Files2;
        this.step32Files2 = [];
        for (let item in files) {
          if (!files[item].isLoading) this.step32Files2.push(files[item]);
        }
        this.step32Files2.push(result["data"]);
        this.isLoading = false;
      } catch (error) {
        console.log(error);
      }
    },
    async handleFileUploadStep4() {
      this.isLoading = true;
      this.step4Files.push({ isLoading: true });
      try {
        let payload = new FormData();
        payload.append("file", this.$refs.file4.files[0]);
        payload.append("folder", "returns");
        const result = await api.auth.uploadFile(payload);
        let files = this.step4Files;
        this.step4Files = [];
        for (let item in files) {
          if (!files[item].isLoading) this.step4Files.push(files[item]);
        }
        this.step4Files.push(result["data"]);
        this.isLoading = false;
      } catch (error) {
        console.log(error);
      }
    },
    deleteStep32Files1(file) {
      let files = this.step32Files1;
      this.step32Files1 = [];
      for (let item in files) {
        if (file["id"] !== files[item]["id"]) {
          this.step32Files1.push(files[item]);
        }
      }
    },
    deleteStep32Files2(file) {
      let files = this.step32Files2;
      this.step32Files2 = [];
      for (let item in files) {
        if (file["id"] !== files[item]["id"]) {
          this.step32Files2.push(files[item]);
        }
      }
    },
    deleteStep4Files(file) {
      let files = this.step4Files;
      this.step4Files = [];
      for (let item in files) {
        if (file["id"] !== files[item]["id"]) {
          this.step4Files.push(files[item]);
        }
      }
    },
    selectBlockStep32() {
      if (this.step32Files1.length > 0) {
        this.selectBlockStep("4", "3-2", {
          files1: this.step32Files1,
          files2: this.step32Files2,
        });
      }
    },
    selectBlockStep4() {
      if (this.step4Input !== "") {
        this.isErrorStep4Input = false;
        this.selectBlockStep("5", "4", {
          value: this.step4Input,
          files: this.step4Files,
        });
      } else this.isErrorStep4Input = true;
    },
    selectBlockStep512() {
      let error = false;
      if (!this.step512Name || this.step512Name === "") {
        this.isErrorStep512Name = true;
        error = true;
      } else this.isErrorStep512Name = false;
      this.step512Inn = this.step512Inn.replace(/[^\d]/g, "");
      if (this.step512Inn.length !== 12) {
        this.isErrorStep512Inn = true;
        error = true;
      } else this.isErrorStep512Inn = false;
      if (!this.step512Bank || this.step512Bank === "") {
        this.isErrorStep512Bank = true;
        error = true;
      } else this.isErrorStep512Bank = false;
      if (!this.step512Amount || this.step512Amount === "") {
        this.isErrorStep512Amount = true;
        error = true;
      } else this.isErrorStep512Amount = false;
      this.step512Number = this.step512Number.replace(/[^\d]/g, "");
      if (this.step512Number.length !== 20) {
        this.isErrorStep512Number = true;
        error = true;
      } else this.isErrorStep512Number = false;
      if (error) return false;
      this.selectBlockStep("stop", "5-1-2", {
        name: this.step512Name,
        inn: this.step512Inn,
        number: this.step512Number,
        bank: this.step512Bank,
        amount: this.step512Amount,
      });
    },
    selectBlockStep52() {
      if (!this.selectDeliveryId) return false;
      let address = "";
      if (this.selectDelivery.type === 1) {
        address = this.selectDelivery.info_point.address;
      } else address = this.selectDelivery.address_raw;
      this.selectBlockStep("stop", "5-2", address);
    },
    sendRepeat() {
      if (this.timer === 0) {
        this.code = "";
        this.isPhone = false;
        this.timer = 91;
        this.timerText = "1:31";
        this.setCheckPhone();
      }
    },
    async setCheckPhone() {
      try {
        await api.auth.checkPhone({});
      } catch (error) {
        console.log(error);
      }
      let that = this;
      setTimeout(function () {
        that.$refs["code"].focus();
        that.checkCode();
      }, 200);
      this.isCheckPhone = true;
      this.setTimer();
    },
    async confirmPhone() {
      let payload = {
        code: this.code,
      };
      try {
        const result = await api.auth.checkPhone(payload);
        if (result["data"]["is_success"]) {
          this.isErrorCode = false;
          this.confirmReturn();
        }
      } catch (error) {
        this.isErrorCode = true;
        console.log(error);
      }
    },
    async createReturn() {
      let source = "ultrabalance.ru";
      if (this.stepValue["1-1"]) source = this.stepValue["1-1"];
      let data = {
        source: source,
      };
      try {
        const result = await api.shop.postShopCreateReturn(data);
        this.editReturnId = result["data"]["return_id"];
        let baseUrl = "?return_id=" + this.editReturnId;
        history.pushState(
          null,
          null,
          location.protocol + "//" + location.host + location.pathname + baseUrl
        );
      } catch (error) {
        console.log(error);
      }
    },
    async updateReturn() {
      if (!this.editReturnId) return false;
      let payload = {
        return_id: this.editReturnId,
        source: "ultrabalance.ru",
        reason_return: this.stepValue["2"],
        fio: this.step512Name,
        inn: this.step512Inn,
        number: this.step512Number,
        bank: this.step512Bank,
        delivery_id: this.selectDeliveryId,
        images: [],
      };
      if (this.step512Amount) payload.amount = this.step512Amount;
      if (this.stepValue["1-1"]) payload.source = this.stepValue["1-1"];
      if (this.stepValue["3-1"]) payload.order_id = this.stepValue["3-1"];
      if (this.stepValue["3-2"]) {
        if (this.stepValue["3-2"]["files1"].length > 0) {
          payload.check_ofd_id = this.stepValue["3-2"]["files1"][0]["id"];
        }
        if (this.stepValue["3-2"]["files2"].length > 0) {
          payload.screen_order_id = this.stepValue["3-2"]["files2"][0]["id"];
        }
      }
      if (this.stepValue["4"]) {
        payload.description = this.stepValue["4"]["value"];
        for (let item in this.stepValue["4"]["files"]) {
          payload.images.push(this.stepValue["4"]["files"][item]["id"]);
        }
      }
      if (this.stepValue["5"]) {
        payload.operation = 1;
        if (this.stepValue["5"] === "Замена или доотправка товара") {
          payload.operation = 2;
        } else if (this.step512Inn === "") payload.operation = 3;
      }
      try {
        await api.shop.postShopUpdateReturn(payload);
      } catch (error) {
        console.log(error);
      }
    },
    async confirmReturn() {
      let payload = {
        return_id: this.editReturnId,
      };
      try {
        await api.shop.postShopConfirmReturn(payload);
        location.reload();
      } catch (error) {
        console.log(error);
      }
    },
    openReturn(item) {
      if (item["source"] === "ultrabalance.ru") {
        this.stepValue["1"] = "Официальный сайт ultrabalance.ru";
      } else {
        this.stepValue["1"] = "Заказ с другого сайта";
        this.stepValue["1-1"] = item["source"];
      }
      this.blockStep = "2";
      if (item["reason_return"]) {
        this.stepValue["2"] = item["reason_return"];
        if (item["source"] === "ultrabalance.ru") this.blockStep = "3-1";
        else this.blockStep = "3-2";
      }
      if (item["order_id"]) {
        this.stepValue["3-1"] = item["order_id"];
        this.blockStep = "4";
      } else if (item["check_ofd"] || item["screen_order"]) {
        let check_ofd = item["check_ofd"];
        if (check_ofd) check_ofd["file_name"] = check_ofd["file_object"];
        let screen_order = item["screen_order"];
        this.stepValue["3-2"] = {
          files1: [check_ofd],
          files2: [],
        };
        if (screen_order) {
          screen_order["file_name"] = screen_order["file_object"];
          this.stepValue["3-2"].files2 = [screen_order];
        }
        this.blockStep = "4";
      }
      if (item["description"] || item["images"]) {
        this.stepValue["4"] = {
          value: item["description"],
          files: [],
        };
        for (let image in item["images"]) {
          image = item["images"][image];
          image["file_name"] = image["file_object"];
          this.stepValue["4"].files.push(image);
        }
        this.blockStep = "5";
      }
      if (item["operation"] === 1 || item["fio"]) {
        this.stepValue["5"] = "Возврат денежных средств за оплаченный заказ";
        this.stepValue["5-1-1"] = "Возврат денежных средств на карту";
        this.step512Name = item["fio"];
        this.step512Inn = item["inn"];
        this.step512Number = item["number"];
        this.step512Bank = item["bank"];
        this.step512Amount = item["amount"];
        this.blockStep = "5-1-1";
      }
      if (item["operation"] === 2 && item["delivery_address"]) {
        this.stepValue["5"] = "Замена или доотправка товара";
        this.stepValue["5-2"] = item["delivery_address"];
        this.blockStep = "5-2";
      }
      if (item["operation"] === 3) {
        this.stepValue["5"] = "Возврат денежных средств за оплаченный заказ";
        this.stepValue["5-1-1"] = "Возврат стоимости заказа на бонусный счет";
        this.blockStep = "5-1-1";
      }
      if (item.status.name === "Черновик") {
        this.editReturnId = item["id"];
        this.isOpenBlockCreate = true;
      } else {
        this.openReturnId = item["id"];
      }
      let baseUrl = "?return_id=" + item["id"];
      history.pushState(
        null,
        null,
        location.protocol + "//" + location.host + location.pathname + baseUrl
      );
      this.getDelivery();
    },
    getValueFile(value) {
      if (value.indexOf("https://") !== -1) return value;
      return this.apiUrl + "media/returns/" + value;
    },
    clickNewReturn() {
      this.blockStep = "1";
      this.stepValue = {};
      this.step11Input = "";
      this.step4Input = "";
      this.step32Files1 = [];
      this.step32Files2 = [];
      this.step4Files = [];
      this.isOpenBlockCreate = true;
    },
    clickReturnClose() {
      this.isOpenBlockCreate = false;
      history.pushState(
        null,
        null,
        location.protocol + "//" + location.host + location.pathname
      );
    },
    closePopupReturn() {
      this.isStepStop = false;
      this.openReturnId = null;
      history.pushState(
        null,
        null,
        location.protocol + "//" + location.host + location.pathname
      );
    },
    async getDelivery() {
      try {
        const result = await api.shop.getDeliveryPointsMaps();
        this.deliveriesPoints = result["data"]["points"];
      } catch (error) {
        console.log(error);
      }
      try {
        const result = await api.shop.getCourierServicePrice();
        this.courierServices = result["data"]["results"];
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
